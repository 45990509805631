<template>
  <div>
    <div class="container">
      <h3>All my Products</h3>
      <!-- <div class="row"> -->
      <button class="btn btn-primary" v-b-modal.modal_add_product>
        Add new Product
      </button>
      <!-- </div> -->
      <b-table :items="allProducts" :fields="fields" class="table">
        <template #cell(sku_client)="data">
          <span>
            <b-link @click="routeToProduct(data.item.sku_client)">{{ data.item.sku_client }}</b-link>
          </span>
        </template>
        <template #cell(img)="data">
          <span>
            <a :href="data.item.img" type="file" target="_blank"
              ><img
                :src="data.item.img"
                alt="Product Image"
                style="width: 100px; height: 100px"
              />
            </a>
          </span>
        </template>
        <template #cell(name)="data">
          <span>{{ data.item.name }}</span>
        </template>
      </b-table>
    </div>
    <b-modal
      ref="modal_add_product"
      id="modal_add_product"
      title="Add Product"
      hide-footer
    >
      <form v-on:submit.prevent="addNewProduct()">
        <div class="form-group">
          <label for="contactInput">SKU</label>
          <input
            type="text"
            class="form-control"
            v-model="skuRequest"
            required
          />
        </div>
        <div class="form-group">
          <label for="contactInput">Prod. Name</label>
          <input
            type="text"
            class="form-control"
            v-model="nameRequest"
            required
          />
        </div>
        <div class="form-group">
          <label for="contactInput">Image Link</label>
          <input
            type="text"
            class="form-control"
            v-model="imgRequest"
            required
          />
        </div>
        <div class="form-group">
          <label for="contactInput">Product Link</label>
          <input
            type="text"
            class="form-control"
            v-model="linkProductRequest"
          />
        </div>
        <button type="submit" class="btn btn-primary float-right">
          Add Product
        </button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      allProducts: [],
      skuRequest: "",
      nameRequest: "",
      imgRequest: "",
      linkProductRequest: "",
      fields: [
        {
          key: "sku_client",
          label: "SKU",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
        {
          key: "img",
          label: "Image",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147); vertical-align: middle",
        },
        {
          key: "name",
          label: "Prod. Name",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async getAllProductsClient() {
      console.log("getAllProductsClient");
      await this.$store.dispatch("getAllProductClient");
      this.allProducts = this.getProductsClient;
      console.log(this.allProducts);
    },
    async addNewProduct() {
      console.log("addNewProduct");
      this.$swal({
        title: "You want add product?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#5a4ec7",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$store.dispatch("addClientProduct", {
              sku: this.skuRequest,
              name: this.nameRequest,
              img: this.imgRequest,
              product_link: this.linkProductRequest,
            });
            this.getAllProductsClient();
            this.$refs.modal_add_product.hide();
            this.$swal({
              title: "Request sent!",
              text: "The request has been sent to the administrator",
              icon: "success",
              confirmButtonColor: "#5a4ec7",
            });
            this.skuRequest = "";
            this.nameRequest = "";
            this.imgRequest = "";
            this.linkProductRequest = "";
          } catch (error) {
            this.$swal({
              title: "Error!",
              text: error,
              icon: "error",
              confirmButtonColor: "#5a4ec7",
            });
            console.log(error);
          }
        }
      });
    },
    async routeToProduct(productId) {
      this.$router.push(`products/${productId}`);
    },
  },
  computed: {
    ...mapGetters(["getProductsClient"]),
  },
  created() {
    this.getAllProductsClient();
  },
};
</script>
<style scoped>
.table {
  text-align: center;
}
h3 {
  margin: 30px;
  text-align: center;
}
.container {
  text-align: center;
}
.btn {
  margin: 10px;
}
</style>